/* .home-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.home-cards .feature_item {
  width: 12rem;
} */

.featureItem{
    /* box-shadow: 0 14px 18px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 1px 12px #00555fe3;
    width: 200px;
    transition: transform 0.5s ease-in-out;
    border-radius: 8%;
    color: #266092;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
  }
  
  .featureItem:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 200px;
    background-color: #266092;
  }
  
  .featureIcon >svg {
    transition: transform 0.5s ease-in-out;
    color: #266092;
    font-size: 10vmin !important;
  }
  
  .featureItem:hover .featureIcon > svg {
    color: white;
    transform: scale(1.2);
  }
  
  .featureTitle {
    transition: color 0.5s ease-in-out;
  }
  
  .featureItem:hover .featureTitle {
    color: white; 
  }
  