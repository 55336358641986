.heading {
  /* display: flex;
  justify-content: center; */
  align-items: center;
  /* color: #969FA7; */
  color: white;
  text-align: center;
  background-color: inherit;
  /* border-radius: 10px; */
  margin: 10px 0px 10px;
  /* font-size: 2vh; */
  /* height: 50px; */
}
/* .Layouts_heading__jdmwF {
  content: "HRM" !important;
  color: black !important;
} */

/* header */
.layoutShrink > div >  :nth-child(2) > header,
.layout > div > :nth-child(2) > header,
/* sidebar */
.layoutShrink > div > aside,
.layout > div >aside,
.layoutShrink > div > aside >div > ul,
.layout > div >aside >div > ul {
  background-color: #266092 !important;
  color: white !important;
  font-weight: 500;
}
.layoutShrink > div > :nth-child(2) > :nth-child(2),
.layout > div > :nth-child(2) > :nth-child(2) {
  margin: 15px 0 0 0 !important;
  padding: 10px !important;
}
.layoutShrink > div,
.layout > div {
  min-height: 100vh;
}
.img {
  width: 35vmin;
  height: 8.5vmin;
  /* width: 18vmin;
  height: 9.5vmin; */
  text-align: center;
}
.layoutShrink > div > aside {
  flex-basis: 65px !important;
  min-width: 65px !important;
  width: 65px !important;
}
.layoutShrink > div > aside > div,
.layout > div > aside > div {
  width: inherit;
  position: fixed;
}

.layout > div > :nth-child(2) > header {
  position: fixed;
  width: 91vw;
  z-index: 1000;
}
.layoutShrink > div > :nth-child(2) > header {
  position: fixed;
  width: 96vw;
  z-index: 1000;
}

.layoutShrink > div > :nth-child(2) > :nth-child(2),
.layout > div > :nth-child(2) > :nth-child(2) {
  margin-top: 3.4rem !important;
}
.selectedTab {
  background-color: rgb(16, 16, 16) !important;
}

/* Profile */
.badgeCircle {
  margin-top: 6px;
  margin-right: 2rem;
  cursor: pointer;
}
.badgeCircle > :nth-child(2) {
  bottom: 35% !important;
}
.profileModal {
  position: absolute;
  top: 135px;
  right: -148px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: #266092;
  color: #e3e3e3;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 24px;
  border: 2px solid black;
} 
.profileText{
  text-align: center;
}

@media screen and (max-width: 1040px) {
  .layoutShrink > div > :nth-child(2) > header {
    width: 94vw;
  }
  .layout > div > :nth-child(2) > header {
    width: 87vw;
  }
}

@media screen and (max-width: 850px) {
  .layoutShrink > div > :nth-child(2) > header {
    width: 92vw;
  }
  .layout > div > :nth-child(2) > header {
    width: 83vw;
  }
}

@media screen and (max-width: 450px) {
  .layoutShrink > div > :nth-child(2) > header {
    width: 85vw;
  }
  .layoutShrink{
    background-color: rgba(0, 0, 0, 0.5);
  }
  .img {
    width: 42vmin;
    height: 12vmin;
    /* width: 22vmin;
    height: 14vmin; */
  }
  .profileModal {
    top: 112px;
    width: 200px;
    right: -100px;
    font-size: 2vmin;
    padding: 10px;
  }
  .profileText{
    font-size: 2.5vmin;
    text-align: center;
  }
  .badgeCircle {
    margin-top: 12px;
    margin-right: 1rem;
  }
}
