.main {
 /* height: 70vh; */
  width: 90%; 
  max-width: 90%;
  margin: 20px 0 0 5%;
  /* padding: 20px; */
  /* border-radius: 10px; */
  /* overflow-y: scroll; */
  /* border: 1px solid #ccc;  */

  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
}

.form {
  font-size: 4vmin;
}
.page {
  margin-bottom: 20px;
  padding: 40px 20px 60px 20px;
  border: 1px solid #ccc;
  font-weight: 600;
}
.page2 {
  margin-bottom: 20px;
  padding-top: 30px;
  border: 1px solid #ccc;
}

.colBorderRight {
  border-right: 1px solid;
}
.colBorderRightEmp {
  font-size: 2vmin;
  padding: 6px;
  border-right: 1px solid;
}
.colBorderLeft {
  border-left: 1px solid;
}
.colAllignBottom {
  display: flex;
  align-items: flex-end;
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  border-left: 1px solid;
  border-bottom: 1px solid;
  font-size: 2vmin;
}
.page3Content {
  font-size: 2.3vmin;
  color: #212529;
}

.colBorderLeft,
.colBorderRight,
.colDiv {
  font-size: 0.9vmax;
  padding: 0 !important;
  padding: 2px 0 0 2px !important;
  padding-bottom: 3px;
  /* font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */

  /* @media (max-width: 768px) {
      font-size: 9px; 
    } */
}
.customDivider {
  border-block-start: 1px solid rgba(0, 0, 0);
  margin: 0 !important;
  /* width: 101%;
  max-width: 101%; */
}
.twentythree {
  display: flex;
  justify-content: center;
  margin-top: 1vmax;
  font-size: 4vmin;
  font-weight: bolder;
}
.text {
  width: 70%;
  height: 20px;
  margin-bottom: 4px;
  border: 1px solid rgb(45, 45, 45);
  background-color: #bdbdbd80;
}
.checkBox {
  margin-top: 3px;
  margin-right: 5px;
  width: 10px;
  height: 10px;
}

.Page3number,
.W9number,
.number {
  border: 1px solid rgb(45, 45, 45);
  /* width: 80px; */
  height: 17px;
  border: none;
  border-bottom: 1px solid;
  background-color: #bdbdbd80;
}
.number {
  width: 70%;
}
.Page3number {
  width: 70%;
  border-bottom: 1px solid #212529;
}
.W9number {
  width: 70px;
}

.sign {
  /* margin-top: 10px; */
  width: 80%;
  height: 14px;
  border: none;
  border-bottom: 1px solid;
}
.page2Image {
  width: 96%;
  height: auto;
  display: block;
  mix-blend-mode: normal;
}

/* w4 Page 3 */
.for1 {
  margin-top: 14vmin;
}
.for2a {
  margin-top: 21vmin;
}
.for2c {
  margin-top: 2vmin;
}
.for3 {
  margin-top: 6vmin;
}

.for4 {
  margin-top: 9vmin;
}
.for13 {
  margin-top: 9.5vmin;
}
.for5 {
  margin-top: 3vmin;
}
.forRemaining {
  margin-top: 6vmin;
}
.title {
  padding-left: 4vmin;
}
.dateDiv {
  margin-top: 3.2vmin;
}
.signature {
  margin-top: 1vmin;
  margin-right: 1vmin;
  /* width: 75%;
  height: 8vmin; */
  border: 2px solid rgb(45, 45, 45);
  
}
/* W9 Form */

.verticalText {
  transform: rotate(270deg);
  transform-origin: bottom;
  transform: rotate(270deg);
  position: absolute;
  left: -19vmin;
  top: 25vmin;
  width: 50vmin;
}
.PartDiv {
  background-color: black;
  color: white;
  text-align: center;
  font-size: 2.5vmin;
  font-weight: 600;
}
.topSider {
  padding-top: 15px;
}
.checkboxColumn {
  margin-left: 4px;
}

.boxFieldTopRightBtm,
.boxFieldTopBtm,
.boxFieldFull {
  margin-top: 4px;
  height: 6vmin;
  width: 3.5vmin;
  border-color: #3f3f3fa7;
}
.boxFieldTopBtm {
  border-left: none;
  border-right: none;
  width: 3vmin;
}
.boxFieldTopRightBtm {
  border-left: none;
  width: 3vmin;
}
.dash {
  margin: 0 4px 0 4px;
}
/* PAd */
@media screen and (max-width: 1024px) {
  .main {
    width: 98%;
    max-width: 98%;
    margin: 20px 0 0 1%;
  }
  .verticalText {
    left: -21.5vmin;
  }
  .form {
    font-size: 2.8vmin;
  }
}

/* MOBILE */
@media screen and (max-width: 450px) {
  .main {
    width: 98%; /* Take up 90% of the container's width */
    max-width: 98%;
    margin: 20px 0 0 1%;
    /* padding: 8px; */
  }
  .form {
    font-size: 2vmin;
  }
  .page {
    padding: 8px;
  }
  .page2 {
    padding-top: 15px;
  }
  .text {
    height: 13px;
    width: 70%;
    margin-bottom: 2px;
    font-size: 8px;
    border: 1px solid rgb(45, 45, 45);
  }
  .checkBox {
    width: 7px;
    height: 7px;
  }
  .number {
    width: 70%;
    height: 10px;
  }
  .sign {
    height: 8px;
  }
  .colBorderRightEmp {
    padding: 1px;
    font-size: 7px;
  }
  .page3Content {
    font-size: 1vmin;
  }
  .Page3number {
    height: 10px;
  }
  .W9number {
    height: 10px;
    width: 20px;
  }
  /* Page 3 */
  .for1 {
    margin-top: 4.5vmin;
  }
  .for2a {
    margin-top: 8.5vmin;
  }
  .for2c {
    margin-top: 2vmin;
  }
  .for3 {
    margin-top: 1.6vmin;
  }
  .for4 {
    margin-top: 2.5vmin;
  }
  .for13 {
    margin-top: 4vmin;
  }
  .for5 {
    margin-top: 0.5vmin;
  }
  .forRemaining {
    margin-top: 2vmin;
  }
  .colBorderLeft,
  .colBorderRight,
  .colDiv {
    padding: 1px 0 1px 1px !important;
  }

  .topSider {
    padding-top: 3px;
  }
  .checkboxColumn {
    margin-left: 2px;
  }
  .boxFieldTopRightBtm,
  .boxFieldTopBtm,
  .boxFieldFull {
    margin-top: 2px;
    margin-bottom: 1px;
    height: 4vmin;
    width: 3.1vmin;
    font-size: 8px;
  }
  .boxFieldTopRightBtm,
  .boxFieldTopBtm {
    width: 2.2vmin;
  }
  .dash {
    margin: 0 1px 0 1px;
  }
  .dateDiv {
    margin-top: 2.5vmin;
  }
  .verticalText {
    left: -11vmin;
    top: 32vmin;
    width: 28vmin;
    font-size: 1.5vmin;
  }
  /* .colAllignBottom{
    padding: 0;
  } */
}
