.status-container {
  text-align: center;
  color: #1677ff;
}

.active {
  background-color: #28a745; /* Green for Active */
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  width: 200px;
}

.rejected {
  background-color: #dc3545; /* Red for Rejected */
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  width: 200px;
}

.pending {
  background-color: #ffc107; /* Yellow for Pending */
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  width: 250px;
  padding: 20px;
}
