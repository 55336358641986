body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: "Poppins", sans-serif;
  /* min-height: 100vh; */
  /* width: 100vw; */
}
/* #root > div > div >  div {
  min-height: 100vh;
} */
.dropdown-toggle-split > :nth-child(2) > :nth-child(4) {
  display: none;
}
.mrt-table > div {
  box-shadow: none;
}
.mrt-table > div > :nth-child(2) > table > thead > tr {
  background-color: #266092 !important;
}
.mrt-table
  > div
  > :nth-child(2)
  > table
  > thead
  > tr
  > th
  > div
  > :nth-child(2)
  > button,
.mrt-table
  > div
  > :nth-child(2)
  > table
  > thead
  > tr
  > th
  > div
  > div
  > :nth-child(2)
  > span
  > svg,
.mrt-table > div > :nth-child(2) > table > thead > tr > th {
  color: white !important;
}
.mrt-table > div > div {
  border-radius: 8px !important ;
}
.modal-form {
  margin-top: 40px;
}
.loading-text {
  margin-top: -8px;
  font-size: 15px;
  color: #118b8f;
}
.loader {
  width: 60px;
  height: 60px;
  border-right: 3px solid #118b8f;
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;
  margin: 0 auto;

  &:before,
  &:after {
    content: "";
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    border-left: 2px solid #118b8f;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
  }

  &:after {
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    border: 0;
    border-right: 1px solid #118b8f;
    animation: none;
  }
}

@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

@keyframes spinRight {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
.status0,
.status1 {
  /* padding: 1px  2px;
  margin-right: 45px;
  color: rgb(225, 225, 225);
  text-align: center;
  border-radius: 10px; */
  color: rgb(225, 225, 225);
  display: inline-block;
  padding: 2px 10px 2px 10px;
  font-size: 0.9em;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgb(47, 47, 47);
}
.status0 {
  color: rgb(74, 73, 73);
  background-color: yellow;
}
.blur-div-top-bar,
.blur-div {
  background-color: #ffffff;
  position: fixed;
  width: 94vw;
  z-index: 1000;
}
.blur-div {
  height: 1rem;
}
.blur-div-top-bar {
  height: 3rem;
  margin-left: 8rem;
}

.nav-bar {
  position: fixed;
  width: 85vw;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  background-color: #266092;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.04) 0px 0px 0px 1px;
  border-radius: 15px;
  margin: 0px 1%;
  padding-top: 8px;
  /* padding-bottom: 6px; */
}
.nav-bar-btn {
  background-color: #266092;
  color: white;
  font-weight: 400;
  font-size: 17px;
}
/* .borders-bottom {
  border-bottom: 4px white solid;
} */
.tab-text-color {
  font-weight: 600;
  color: #1a1a1a;
  background-color: white;
  padding-bottom: 1vmin;
  /* border-radius: 10px; */
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  /* border-bottom-right-radius: -10px;
  border-bottom-left-radius: 10px; */
}
.form-heading {
  display: flex;
  justify-content: space-around;
  color: #0f91d2;
  font-size: 15px;
}
/* Image Field */
.upload-div {
  display: flex;
  border-width: 1px;
  border-radius: 6px;
  border-style: solid;
  width: 100%;
  padding: 2px 6px 0 10px !important;
  color: #b7b5b5;
}
.image-div,
.image-div-error {
  padding-left: 5px;
  border-radius: 6px;
  height: 30px;
  border: 2px solid rgb(45, 45, 45);
}

.image-div-error {
  /* background-color: #e7e7e7; */
  color: rgb(240, 14, 14);
  border: 2px solid rgb(240, 14, 14);
}
.image-div {
  background-color: rgb(195, 195, 195);
  color: rgb(58, 58, 58);
}
.delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgba(152, 23, 23, 0.856);
  color: white;
  border-left: 2px white;
  cursor: pointer;
}
.upload-error .ant-upload {
  border-radius: 6px;
  border: 1px solid red;
}

@media screen and (max-width: 1040px) {
  .nav-bar {
    width: 82vw;
  }
}
@media screen and (max-width: 850px) {
  .nav-bar {
    width: 80vw;
  }
}
@media screen and (max-width: 450px) {
  .nav-bar {
    width: 78vw;
    padding-top: 0px;
    border-radius: 0px;
  }
  .nav-bar-btn {
    padding-top: 4px;
    font-size: 1.4vmax;
  }
  .form-heading {
    font-size: 1.3vmax;
  }
}

.pdf-iframe {
  width: 100%;
  height: 500px;
  border: #0f91d2 2px solid;
  border-radius: 12px;
  /* background-color: #0f91d2 !important; */
}
